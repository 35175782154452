import styles from "./response-styles.module.css";

const Response = ({ type, children, setResponse, aligned }) => {
    return (
        <div
            onClick={() => {
                setResponse(null);
            }}
            className={`${styles.response} ${
                type === "error" ? styles.error : styles.success
            } ${aligned ? styles.aligned : styles.fixed}`}
        >
            <img
                src={`${
                    type === "error" ? "/images/error.svg" : "/images/check.svg"
                }`}
                alt=""
            />

            <span>{children}</span>

            <button
                onClick={() => {
                    setResponse(null);
                }}
            />
        </div>
    );
};

export default Response;
